import type { Moment } from "moment";
import type { NamespacePortfolioPropertyOpex } from "../user-preferences-helpers";
import { type CategoryCell, getDateRange, getDateRangeEntries, fitDateString, getSetValue } from "./portfolio-balance-helpers";
import type { Currency } from "../apollo/types";
import { arrayLast, hasValue } from "../common-helpers";
import type { OpexData } from "~/composables/queries/useOpexQuery";
import { MetricPeriodType } from "~/graphql/generated/graphql";

export type OpexCategoryBenchmarkDataPoint = {
  target: OpexCategoryDataPointValue;
  benchmark: OpexCategoryDataPointValue;
  diff: OpexCategoryDataPointValue;
  diffPercentage: OpexCategoryDataPointValue;
};
export type OpexCategoryDataPointValue = { accumulated: number; accumulatedAnnualised: number; value: number; valueAnnualised: number };
export type OpexCategoryData = { id: string; name: string; currency: Currency; values: OpexCategoryDataPointValue[] };
export type OpexCategoryBenchmarkData = {
  id: string;
  name: string;
  currency: Currency;
  values: OpexCategoryBenchmarkDataPoint[];
  last: OpexCategoryBenchmarkDataPoint;
};

export const getOpexCategoryChartColumns = (preferences: NamespacePortfolioPropertyOpex) => {
  const dateRange = getDateRange(preferences.dateRange, preferences.periodType, preferences.customDateStart, preferences.customDateEnd);

  return getDateRangeEntries(dateRange, preferences.periodType);
};

export const getOpexCategoryBenchmarkData = (
  columns: Moment[],
  property: OpexData,
  portfolio: OpexData,
  preferences: NamespacePortfolioPropertyOpex
): OpexCategoryBenchmarkData[] => {
  const columnIndices = new Map<string, number>();

  const periodTypeMonthCount = preferences.periodType === MetricPeriodType.Monthly ? 1 : preferences.periodType === MetricPeriodType.Quarterly ? 3 : 12;

  const normaliseByField: keyof OpexData["entityData"] = preferences.normaliseBy === "area" ? "totalArea" : "totalTenancies";

  const createRow = (cell: CategoryCell) => ({
    id: cell.categoryId,
    name: cell.categoryName,
    values: columns.map(() => ({ accumulated: 0, value: 0, accumulatedAnnualised: 0, valueAnnualised: 0 })),
    currency: cell.currency,
  });

  const getMonthDiff = (index: number) => Math.abs(columns[0].diff(columns[index], "months")) + periodTypeMonthCount;

  /** Groups cell values by category id */
  const setRowValue = (cell: CategoryCell, map: Map<string, OpexCategoryData>) => {
    const periodIndex = getSetValue(cell.period, columnIndices, () => {
      const period = fitDateString(cell.period, preferences.periodType);

      return columns.findIndex((c) => c.isSame(period));
    });

    if (!hasValue(periodIndex) || periodIndex === -1) return;

    const row = map.get(cell.categoryId);

    if (!row) return;

    row.values[periodIndex].value += -cell.totalCostWithVat;
  };

  /** Normalises a category row by selected unit + create annualised value */
  const normaliseRow = (row: OpexCategoryData, data: OpexData) => {
    for (let i = 0; i < row.values.length; i++) {
      const current = row.values[i];

      current.value /= data.entityData[normaliseByField];

      current.valueAnnualised = current.value * (12 / periodTypeMonthCount);
    }
  };

  /** Accumulates column values for a category row + the amount of 12 month periods of current accumulation. Will extrapolate if less than 12 */
  const accumulateRow = (row: OpexCategoryData) => {
    for (let i = 0; i < row.values.length; i++) {
      const current = row.values[i];
      const prev = row.values[i - 1];

      const monthDiff = getMonthDiff(i);

      const annualiseFactor = 12 / monthDiff;

      current.accumulated = current.value + (prev?.accumulated ?? 0);

      current.accumulatedAnnualised = current.accumulated * annualiseFactor;
    }
  };

  /** Iterate through all "cells" which hold accumulated data for a category in period  */
  const getCategoryRows = (data: OpexData) => {
    const map = new Map<string, OpexCategoryData>();

    data.cells.forEach((cell) => {
      getSetValue(cell.categoryId, map, () => createRow(cell));

      setRowValue(cell, map);
    });

    const rows = Array.from(map.values());

    rows.forEach((row) => normaliseRow(row, data));

    rows.forEach(accumulateRow);

    return rows;
  };

  const portfolioCategoryRows = getCategoryRows(portfolio);
  const propertyCategoryRows = getCategoryRows(property);

  /** Merging rows for benchmark */

  /** This is where most of the math happens */
  const getBenchmarkDataPoint = (
    portfolioCategoryValue: OpexCategoryDataPointValue,
    propertyCategoryValue: OpexCategoryDataPointValue = { accumulated: 0, value: 0, accumulatedAnnualised: 0, valueAnnualised: 0 }
  ): OpexCategoryBenchmarkDataPoint => {
    const getDiffPercentage = (diff: number, total: number) => Math.sign(diff) * Math.abs((diff * 100) / total);

    const target = { ...propertyCategoryValue };
    const benchmark = { ...portfolioCategoryValue };
    const diff = {
      accumulated: target.accumulated - benchmark.accumulated,
      accumulatedAnnualised: target.accumulatedAnnualised - benchmark.accumulatedAnnualised,
      value: target.value - benchmark.value,
      valueAnnualised: target.valueAnnualised - benchmark.valueAnnualised,
    };
    const diffPercentage = {
      accumulated: getDiffPercentage(diff.accumulated, benchmark.accumulated),
      accumulatedAnnualised: getDiffPercentage(diff.accumulatedAnnualised, benchmark.accumulatedAnnualised),
      value: getDiffPercentage(diff.value, benchmark.value),
      valueAnnualised: getDiffPercentage(diff.valueAnnualised, benchmark.valueAnnualised),
    };

    return { benchmark, diff, diffPercentage, target };
  };

  const benchmarkRows: OpexCategoryBenchmarkData[] = [];

  for (let i = 0; i < portfolioCategoryRows.length; i++) {
    const portfolioRow = portfolioCategoryRows[i];
    const propertyRow = propertyCategoryRows.find((c) => c.id === portfolioRow.id);

    const benchmarkRowValues: OpexCategoryBenchmarkData["values"] = [];

    for (let j = 0; j < portfolioRow.values.length; j++) {
      benchmarkRowValues.push(getBenchmarkDataPoint(portfolioRow.values[j], propertyRow?.values[j]));
    }

    benchmarkRows.push({ ...portfolioRow, last: arrayLast(benchmarkRowValues), values: benchmarkRowValues });
  }

  benchmarkRows.sort((a, b) => a.name.localeCompare(b.name));

  return benchmarkRows;
};
