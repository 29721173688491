import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { useQuery } from "@vue/apollo-composable";
import { ResultsOpexPropertyDocument, ResultsOpexPortfolioDocument, AssetManagementCategoryType } from "~/graphql/generated/graphql";
import { useAreaQuery } from "./useAreaQuery";
export var useOpexQuery = function useOpexQuery(variables) {
  var preferences = usePreferencesNamespace("preferencesNamespacePortfolioPropertyOpex");
  var dateRange = useOpexSelectedDateRange();
  var queryVariables = function queryVariables() {
    var _dateRange$result$val, _dateRange$result$val2, _preferences$result$v;
    return {
      input: {
        categoryType: AssetManagementCategoryType.Opex,
        from: (_dateRange$result$val = dateRange.result.value) === null || _dateRange$result$val === void 0 ? void 0 : _dateRange$result$val.from,
        to: (_dateRange$result$val2 = dateRange.result.value) === null || _dateRange$result$val2 === void 0 ? void 0 : _dateRange$result$val2.to,
        periodType: (_preferences$result$v = preferences.result.value) === null || _preferences$result$v === void 0 ? void 0 : _preferences$result$v.periodType,
        propertyId: variables.value.propertyId,
        portfolioId: variables.value.portfolioId
      }
    };
  };
  var propertyQuery = useQuery(ResultsOpexPropertyDocument, queryVariables, function () {
    return {
      enabled: !!preferences.result.value && !!dateRange.result.value && !!variables.value.propertyId
    };
  });
  var portfolioQuery = useQuery(ResultsOpexPortfolioDocument, queryVariables, function () {
    return {
      enabled: !!preferences.result.value && !!dateRange.result.value && !!variables.value.portfolioId
    };
  });
  var query = computed(function () {
    return variables.value.propertyId ? propertyQuery : variables.value.portfolioId ? portfolioQuery : null;
  });
  var areaQuery = useAreaQuery(variables);
  var cells = computed(function () {
    var _query$value, _query$value$result$v, _query$value2, _query$value2$result$;
    return (_query$value = query.value) !== null && _query$value !== void 0 && (_query$value$result$v = _query$value.result.value) !== null && _query$value$result$v !== void 0 && _query$value$result$v.categoryCells ? _toConsumableArray((_query$value2 = query.value) === null || _query$value2 === void 0 ? void 0 : (_query$value2$result$ = _query$value2.result.value) === null || _query$value2$result$ === void 0 ? void 0 : _query$value2$result$.categoryCells).sort(function (a, b) {
      return a.order - b.order;
    }) : undefined;
  });
  var result = computed(function () {
    return areaQuery.result.value && cells.value ? {
      cells: cells.value,
      entityData: areaQuery.result.value
    } : undefined;
  });
  var loading = computed(function () {
    var _query$value3;
    return areaQuery.loading.value || ((_query$value3 = query.value) === null || _query$value3 === void 0 ? void 0 : _query$value3.loading.value) || preferences.loading.value || dateRange.loading.value;
  });
  return {
    result: result,
    loading: loading
  };
};